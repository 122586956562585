import image1 from 'assets/img/gallery/thumb/1.jpg';
import image2 from 'assets/img/gallery/thumb/2.jpg';
import image3 from 'assets/img/gallery/thumb/3.jpg';
import image4 from 'assets/img/gallery/thumb/4.jpg';

export const IMAGES =
  [
    {
      src: 'https://lh3.googleusercontent.com/NNCFCOMnONsmkLb5GxYsgks2rNu36UAArltwHV27Fnm-DPaatylTlktQOV1419Zc2LVZs9hsFeHA5vdZv04NEI78iyy3CWB0Nv6IrU1VO1zrrKg5oUAegagSk_kQxSvBUIcVOuq0OHroL60h3EaM_5wXCoAv8p7hS0qZzOqKCN5k81vYtSCKfjIgTlzRLmO9DSO4jB6q7OeKbI53VcwqE8Vt6pRwjVzjSBgSbtLIpSpO3x9XcxHGHhv2pBHqH-788h7gDPnnHKBmrC-g1cTBLxgp1EtV6nnt4Waz_hh63jTUGBv9kOAhQ9xXzDCkcsMtzVba_SbTQyMF9rpSNnpTJBmCbwa0b6A6XSXjyHQl85JQH9kODwgEUV_JTvqdvOg7bXsKc2Tdut8_9NhT6LEUPjoYno2fCaWF-XpvWv2BUgdvMhiTN7i1XFC-_JpXT80xeHPU80HkFknamnRJKCuftC7_bUb8r69SYMj2hYpptPtP7-6qjgszinuZPgTXZR8_9xk4qPBy0wshZYwy7Q25WnThSfufOM19IyT-n2AyAEDbd560kV9ddRc94hP-SmFIuSq3eLhaEiFRbbNpe5Da_s5HBOQx-_0T4mSR80dk2sXsEODgDMhhMpsDBFv0NNQbYqUssHXg-rZ5-WbGTzHhH8P6oRTasD3sxzTYnX_tZlWSL7hvZ0O4B-z9YPLA2lVtFxRy9LOlMqgnqjXMMrdNWYNJSFNcni-8kbnVmghGxq4Dr7E=w640-h480-no',
      thumbnail: image1,
      thumbnailWidth: 283,
      thumbnailHeight: 212,
    },
    {
      src: 'https://lh3.googleusercontent.com/US9FSpvAwo0xxLSLE28IlOgc2s2qyBJ7qp32cKZt_mVvxZ0SN2IB1sp5r_9dNf08oPOb-o5hNlAAxikrtYbM_ULpXTkjzVTqEX8HQeyaLWB8Lv0a82mY89U552TeLK1pz5thj1Rum7f2uKLeGFdN_sZVfD_DDD19D6U3ojukjiIsfNzhh0ed6fHwAMBNXQIMdSxYwcb7s0KSkKxQ71YSqD2K0nhK1XLIiidYPHlb2463QJc2UTwAqydvLJKjZqMFIbV2GMMvHTEcqehIZj9k5Ba9TXFnMJ61PTnKOc8UxbdzVNhDK1jgLZ5K927W-alB5Sr-Q25uKC_78m6O_-PbsQ_cNQOG0OptdlqXNTP1RE8WpK5ZvqACq0MHDng4gOouWDypFz4zzUD2OTJg3A56feVSQzvgYDSUrkxWzD0KPY1MqbYCFdp2R4S6UQ5_tKZU4xJqd2IKCh9PSM486umNF6jsK1w85huwGXI48H_jGBTXxd_DtxOKcD5gymPnlIj6sAIEpoYP9CaSEAWrDdiBmeGqyM6isMtPGeRZF55cVfilTIwU9jQXMDoL51XLRBIrOp271I_iMUoGPzf8aLjVhOShYJO41JF1IUVavFg3DZrqxmc8AI6jWktjL6OQ8BF8WsGQxf7NpvQTCnpiAbn3VRcsdLqMKL241X6--k8SFYj9titlse1xu5Tg7SsfnRpPJF0yPhSihBulqFYEA8vy_DoDDNOetQndh5gCFbQXoDnNh20=w640-h480-no',
      thumbnail: image2,
      thumbnailWidth: 283,
      thumbnailHeight: 212,
    },
    {
      src: 'https://lh3.googleusercontent.com/XWjTAyxSglhyRngqyeyR2U2v_pu4jLgpeT5_TF14AtBdVDaYTkqyZK4aCqPr6EQ_TlFCH0dCRQM7opOu2gS8guhnt9xo_As9NFl1_DkmJlmKRhfWSBOnuqNlgxkVrG5xr8K9iIkmLcsSq3kdKxOYJXd9Y7SQZ5OMqQrZKGbJHibOle-mOXaBElWGjnHuat6p8-OoxeZ9U0NKvNRxOez4S_5L1oJ-OriEt8zN0tYe_iqsV-xZWy48u3gsRD4hFAE6PwrX1Pn5Rj2kJT8MWpCknU8ApSM2_g_orIhtRBpGYMqWX8J3tivXqvUGOTfLzsmo8ImP-PgCd35fa7ZRd3fyaM82N0-MuVnBii6GcFuTIJ_-mNESwoam9mTK_8ZJmOEZOsyXW5AWOvAaRNgQRMDeXQaGlKDOloEFr_m3fOHIwHMrnU7BddHLGLVpxltOaSmPp9ItOuBCXWBMCepscJU8ktEoWz-cqpoOxP6ZYBON13eVg3SA_WGCQrtKBoNjUQBboJfZOgouBUn04VkOy-YTbgluFXyZIhowZy8gl8qiqMXzNgh7oOOIND8fP2ZJ87WmR-NZVqwoksc9tGhz4RljTSMzeADJ7fNXL6R456ubPG0ZV_a-gUXLy_4RMbtgErDjCa_xQ4ITPJ4-VTSWT_0tZluE_AwppFfaOnVncxAHgXJpVOJVA1UvvzNdal5hcNYvGiEQaQU7TRNnyvmuQ23D_SPubY9r-gsBapTf1ZgI1RkskjQ=w923-h615-no',
      thumbnail: image3,
      thumbnailWidth: 318,
      thumbnailHeight: 212,
    },
    {
      src: 'https://lh3.googleusercontent.com/vqfebPaEU91YiAnXfz4y5KDVVevTSTMjaIJoTO-gOhglV59XU5tQz6ChfEPv7SqSuQGOGAW6UBsj-GBL3iwWFjROeQy8ANgSxPqNpt9s8G_B3AzU49MylYZd_8N1cklfxH8_ZGY-Te_YGfozM2CYVeW16U72ap17_36Zaht30Sc7_GD5Dma4Wnm5xZvQ3DRZErgW-U7iVlyuekPm8zZY72Kiwuq5W-oLkgXd2Fdrd5znVeX5cdxYVgo7vrVg0oisBot1doz5EPCQqBoPGvpQhWZEBqEy6QaQDR5cXNFODKUw4rTwOOZifTpflxwZgzNDDU0MvYdJ3gQ5yOwxyHk2-42eBlh1D4lSbNDiK_zszODEA46CGM2rt34ZvEfZlF5K7zlIwtlDVANvyrXETqMTxkfayAeRiZ8yiXaiQUBBecegiTcRX6Pp3qklRJjoL0vfhd5-Uznm8cvlcHUBcWTkECEHMe-6nXkrPDR_e5BPyE6Tq490qdnrzFzsgH2HGzZc97i-dAplgrAsAtSD6-4FQjd1g2GI7ho6uobzd1_ZcNx-xAAiNNgTfTtdbR351ySFxcoVKJQa3nbMgfsZ1LTae3qOXyrr0N4uk054AQF9dAlpQHF5qgyHs2Sz-AzB3eTko8l7j-Ra9wgDl2tzgRJH4f6kZDr3VR-DnofT7ZkQG7Mf4-2zEXXnpkvKedgL0ek168Q_T4FGB292C6viwnzx3qKpqfk6exOrwpXl-KF5HGFqcA0=w640-h480-no',
      thumbnail: image4,
      thumbnailWidth: 283,
      thumbnailHeight: 212,
    },
  ];
